import { scrollBottom } from '../utilities/scroll';

export default class {
    constructor({
        id,
        state,
    }) {
        const el = document.getElementById(id);
        const scroll = el.querySelector('button');
        const loadingAnimation = el.querySelector('.loading-animation');
        const heroVid = el.querySelector('.homepage-hero__video');
        const headlineText = el.querySelector('.headline');
        const header = document.querySelector('body > header > .header');
        const lowerUi = el.querySelector('.homepage-hero__lower-ui');
        const dropUpWrapper = el.querySelector('.homepage-hero__dropup');

        function handleScroll() {
            scrollBottom(el, state.headerNavHeight);
        }

        if (scroll) scroll.addEventListener('click', handleScroll);

        // Step 1. loading animation
        setTimeout(() => {
            loadingAnimation.classList.add('loading-animation--animated');
        }, 500);

        loadingAnimation.addEventListener('animationend', () => {
            loadingAnimation.classList.add('loading-animation--fade-cirle');

            // Step 2. fade out circle surrounding logo
            const circleFade = document.querySelector('.loading-animation--fade-cirle');
            circleFade.addEventListener('transitionend', () => {
                // Step 3. fade in video and expand
                heroVid.classList.add('fade-video');
                // Step 4. slide logo out; text and UI elements in
                loadingAnimation.classList.add('loading-animation--slide-logo');
                headlineText.classList.add('headline--animated');
                header.classList.add('header--slide-in');
                lowerUi.classList.add('homepage-hero__lower-ui--fade-in');
                // Step 5. remove the new-session class after the intro animation completes (it was messing with other UI)
                setTimeout(() => {
                    document.querySelector('body').classList.remove('new-session');
                }, 4000)
            });
        });

        dropUpWrapper && dropUpWrapper.addEventListener('click', () => {
            dropUpWrapper.classList.toggle('is-active');
        });

        // temporary solution to force Safari to pull video from cache so it doesn't get hung on return visits to homepage
        const videoRequest = fetch('/images/homepage-hero.mp4').then(response => response.blob());
        videoRequest.then(blob => heroVid.src = window.URL.createObjectURL(blob));
    }
}
